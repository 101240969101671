import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import { Link } from 'react-router-dom';
import { ReactComponent as ExternalOpenIcon } from '../../../../../shared/icons/external-open.svg';
import { ReactComponent as TrashcanIcon } from '../../../../../shared/icons/trashcan-icon.svg';
import { ReactComponent as DragIcon } from '../../../../../shared/icons/drag.svg';
import styles from './styles.module.scss';
import tooltipStyles from '../../../../tooltip/styles.module.scss';
import { OverlayTrigger, Tooltip as BootstrapTooltip, Button } from 'react-bootstrap';
import classNames from 'classnames';

interface DocumentAttachmentItemProps {
  id: string;
  index: number;
  attachment: {
    filename: string;
    url: string;
  };
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  handleRemoveAttachmentClick: (id: string) => void;
  canManipulate: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DocumentAttachmentItem = ({ id, index, attachment, handleRemoveAttachmentClick, moveItem, canManipulate = false }: DocumentAttachmentItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'attachment',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item: DragItem, monitor) => {
      if (!ref.current || !canManipulate) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'attachment',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (canManipulate) {
    drag(drop(ref));
  }

  const itemStyles = classNames(styles.attachmentItem, canManipulate && styles.canManipulate);

  return (
    <div ref={ref} className={itemStyles} style={{ opacity: canManipulate && isDragging ? 0.5 : 1 }}>
      <span className={styles.attachmentNumber}>{index + 1}</span>
      {canManipulate && (
        <span className={styles['dragHandle']}>
          <DragIcon className={styles['dragIcon']} />
        </span>
      )}

      <span className={styles.attachmentTitle}>
        <OverlayTrigger
            placement="bottom"
            overlay={
              <BootstrapTooltip 
                id={`tooltip-attachment-${id}`}
                data-testid={`tooltip-attachment-${id}`}
                className={tooltipStyles.primaryTooltip}
              >
                {attachment.filename}
              </BootstrapTooltip>
            }
          >
            <span className={styles.filenameWrapper}>{attachment.filename}</span>
          </OverlayTrigger>
      </span>

      <div className={styles.attachmentActions}>
        <Link to={attachment.url} target="_blank" className={styles.openAttachment}>
          <ExternalOpenIcon height="16" width="16" />
        </Link>
        {canManipulate && (
          <Button
            type="button"
            className={styles.removeAttachment}
            onClick={() => handleRemoveAttachmentClick(id)}
          >
            <TrashcanIcon />
          </Button>
        )}

      </div>
    </div>
  );
};

export default DocumentAttachmentItem;