import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../../../../titleFormattedMessage';
import Tooltip from '../../../../tooltip';
import DocumentAttachmentItem from './documentAttachmentItem';
import { ATTACHMENTS_COUNTERPARTY_WARNING } from '../../constants/EditorConstats';
import { Warning } from '../../../../shared/warning';

interface DocumentAttachmentsSidebarProps {
  attachments: any[];
  handleFileUploadModal: () => void;
  handleRemoveAttachmentClick: (id: any) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  canUpload: boolean;
}

const DocumentAttachmentsSidebar = ({ attachments, handleFileUploadModal, handleRemoveAttachmentClick, moveItem, canUpload = false }: DocumentAttachmentsSidebarProps) => {
  const MANAGE_ATTACHMENTS_TOOLTIP = "Attachments will be added to the end of the document when sent for signing or exported."

  const ATTACHMENTS_LIMIT = 10;

  const attachmentsListHeading = canUpload ? "Manage attachments" : "All attachments";

  const isAtLimit = attachments.length >= ATTACHMENTS_LIMIT;

  const uploadButtonVariant = isAtLimit ? "secondary" : "primary";

  return (
    <div className={classNames(styles.attachmentSidebarWrapper)}>
      <h2>
        Attachments
        <span className={styles.tooltip}>
          <Tooltip testId="manage-attachments-tooltip" placement="right">
            {MANAGE_ATTACHMENTS_TOOLTIP}
          </Tooltip>
        </span>
      </h2>
      {canUpload && (
        <div className="d-flex justify-content-between align-items-center w-100 pb-4">
          <Button variant={uploadButtonVariant} className="w-100" onClick={ handleFileUploadModal } disabled={!canUpload || isAtLimit}>
            <TitleFormattedMessage id="cta.sidebar-upload-attachment" defaultMessage="Upload" />
          </Button>
        </div>
      )}
      {(attachments.length === 0) && (
        <div className={styles.noAttachments}>
          No attachments found.
        </div>
      )}
      {(attachments.length > 0) && (
        <>
          {canUpload && (
            <div className="pl-1 pr-1 m-auto">
              <Warning text={ATTACHMENTS_COUNTERPARTY_WARNING} largeCentered />
            </div>
          )}

          <div className={styles.attachmentsList}>
            {attachments.map((extraAttachment, idx) => (
              <DocumentAttachmentItem 
                key={extraAttachment.id} 
                id={extraAttachment.id} 
                index={idx} 
                moveItem={moveItem}
                attachment={extraAttachment.attachment} 
                canManipulate={canUpload}
                handleRemoveAttachmentClick={handleRemoveAttachmentClick} 
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentAttachmentsSidebar;
