import { Dispatch } from 'redux';
import {
  setLeaseDocument,
  setLeaseDocumentId,
  setLeaseDocumentStatus,
  setLeaseDocumentPossession,
  setLeaseDocumentTitle,
  setLeaseDocumentTeamId,
  setLeaseDocumentLastUpdated,
  setLeaseDocumentReceiverTeamId,
  resetLeaseDocumentState,
  setLeaseDocumentEversignStatus,
  setLeaseDocumentCloudDocumentVersion,
  setLeaseDocumentSuggestions,
  setDocumentEversignPdfUrl,
  setDocumentShouldReturnForReceiverReview,
  setDocumentRestrictedEditingMode,
  setDocumentSource,
  setDocumentImportedAssetUrl,
  setDocumentImportedAssetContentType,
  setDocumentGeneratedDocumentPdfUrl,
  setDocumentApprovalRequest,
  setIsDocumentEditable,
  setInteractionsFeedToken,
  setDocumentSigners,
  setDocumentOriginalImportedAssetUrl,
  setIsReceiverCollaborationEnabled,
  setDocumentTrackChangesMode,
  setIsDocumentTitleEditable,
  setIsEligibleSigningPrep,
  setLeaseDocumentTemplate,
  setLeaseDocumentName,
} from '../../../store/lease-document/actions/lease-document.actions';
import { DocumentPayload } from '../../api/lease-document-interface.types';
import { LeaseDocumentTemplateType, LeaseDocumentType } from '../../../components/lease-wizard/lease-document.types';
import { setLeaseDocumentSigners } from '../../../store/lease-document/actions/set-lease-document-signers.action';

/**
 * This is a reusable abstraction to make sure we update all the things after the
 * state has changed for the lease document.
 *
 * It's important to note that not all lease types (Draft, Offer)
 * have the same deals.
 *
 * As this diverges, it may make sense to sacrifice DRY'ness in the interest of readability
 */

// Todo we need to add some kind of parser for such requests to check that necessary deals exist
const validateDocumentBody = (documentId: string, document: LeaseDocumentType): LeaseDocumentType => {
  if (typeof document?.body !== 'string') {
    console.error(`Wrong document body: ${document?.body}, documentId: ${documentId}`);

    return { ...document, body: '' };
  }

  return document;
};

const validateDocumentTemplateBody = (documentId: string, template: LeaseDocumentTemplateType): LeaseDocumentType => {
  if (typeof template?.body !== 'string') {
    console.error(`Wrong document body: ${template?.body}, documentId: ${documentId}`);

    return { ...template, body: '' };
  }

  return template;
};

export const dispatchLeaseDocumentResponseToStore = (
  dispatch: Dispatch,
  response: DocumentPayload,
  updateDocument = true
) => {
  if (updateDocument) {
    if (response.document) {
      const document = validateDocumentBody(response.id, response.document);
      dispatch(setLeaseDocument(document));
    }
    if (response.template) {
      const template = validateDocumentTemplateBody(response.id, response.template);
      dispatch(setLeaseDocumentTemplate(template));
    }
  }
  if (response.name || response.title) {
    const responseName = response.name || response.title;

    dispatch(setLeaseDocumentTitle(responseName)); // for templates
    dispatch(setLeaseDocumentName(responseName)); // for documents
  }

  dispatch(setLeaseDocumentId(response.id));
  dispatch(setLeaseDocumentStatus(response.status));
  dispatch(setInteractionsFeedToken(response.interactionsFeedToken));
  dispatch(setLeaseDocumentPossession(response.currentTeamPossession));
  dispatch(setLeaseDocumentLastUpdated(response.lastUpdated));
  dispatch(setLeaseDocumentSuggestions(response.documentSuggestions));
  dispatch(setLeaseDocumentSigners(response.signers));
  dispatch(setLeaseDocumentEversignStatus(response.eversignStatus));
  dispatch(setLeaseDocumentCloudDocumentVersion(response.document?.cloudDocumentVersion ?? 0));
  dispatch(setDocumentEversignPdfUrl(response.eversignPdfUrl || null));
  dispatch(setDocumentApprovalRequest(response.approvalRequest || null));
  dispatch(setIsDocumentEditable(response.isEditable || false));
  dispatch(setIsDocumentTitleEditable(response.isTitleEditable || false));
  typeof response.isEligibleForSigningPrep === 'boolean' &&
    dispatch(setIsEligibleSigningPrep(response.isEligibleForSigningPrep || false));
  dispatch(setIsReceiverCollaborationEnabled(response.receiverCollaborationEnabled || false));
  dispatch(setDocumentRestrictedEditingMode(response.editorConfiguration?.restricted_editing || false));
  dispatch(setDocumentTrackChangesMode(response.editorConfiguration?.track_changes || false));
  dispatch(setDocumentShouldReturnForReceiverReview(response.shouldReturnForReceiverReview));
  dispatch(setDocumentSource(response.source));
  dispatch(setDocumentSigners(response.signers));
  dispatch(setDocumentImportedAssetUrl(response.importedAssetUrl));
  
  if (response.originalImportedAssetUrl) {
    dispatch(setDocumentOriginalImportedAssetUrl(response.originalImportedAssetUrl)); 
  }

  dispatch(setDocumentImportedAssetContentType(response.importedAssetContentType));
  dispatch(setDocumentGeneratedDocumentPdfUrl(response.generatedDocumentPdfUrl));

  if (response.team) {
    dispatch(setLeaseDocumentTeamId(response.team.id));
  }

  if (response.receiverTeam) {
    dispatch(setLeaseDocumentReceiverTeamId(response.receiverTeam.id));
  }
};

export const dispatchEraseLeaseDocumentValuesFromStore = (dispatch: Dispatch) => {
  dispatch(resetLeaseDocumentState());
};
