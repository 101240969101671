import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { ReactComponent as ChevronRightIcon } from '../../../shared/icons/chevron-right.svg';
import { ReactComponent as InfoIcon } from '../../../shared/icons/info-unfilled-icon.svg';
import { Table } from '../../../components/table';
import { TitleFormattedMessage } from '../../../components/titleFormattedMessage';
import styles from './styles.module.scss';
import Tooltip from '../../../components/tooltip';

interface LeaseTemplateModalTableProps {
  data: any[];
  selectTemplate: (templateId: string | number, teamId: string, teamName?: string) => void;
  selectedTemplateId: string | number;
}

export const LeaseTemplateModalTable = ({ data, selectTemplate, selectedTemplateId }: LeaseTemplateModalTableProps) => {
  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="lease.template.name" defaultMessage="Template Name" />,
        accessor: 'name',
        Cell: ({ row: { original } }: any) => {
          return (
            <div>
              <div>
                {original.description && original.description.length > 0 && (
                  <Tooltip 
                    testId="lease-template-tooltip" 
                    placement="bottom" 
                    icon={<InfoIcon className='text-primary mr-1' />}
                  >
                    {original.description}
                  </Tooltip>
                )}
                {original.name}
              </div>
              <div className="text-muted">{original.team?.name}</div>
            </div>
          );
        },
      },
      {
        Header: <FormattedMessage id="lease.template.type" defaultMessage="Type" />,
        accessor: 'docTemplateType',
        width: 120,
      },
      {
        Header: '',
        id: 'actions',
        width: 50,
        disableSortBy: true,
        Cell: ({ row }: { row: any }) => {
          const { id, team } = row.original;
          const isTemplateSelected = id === selectedTemplateId;

          return (
            <div className="d-flex align-items-center justify-content-end">
              <Button
                variant="link"
                className={classNames(styles.selectedTemplateButton, 'ml-2', {
                  'text-muted font-weight-bold': isTemplateSelected,
                })}
                onClick={() => selectTemplate(id, team?.uuid, team?.name)}
                data-testid="lease-template-select"
              >
                <TitleFormattedMessage
                  id="lease.template.cta.select"
                  defaultMessage={isTemplateSelected ? 'Selected' : 'Select'}
                />
                {!isTemplateSelected && <ChevronRightIcon className="ml-2" />}
              </Button>
            </div>
          );
        },
      },
    ],
    [selectTemplate, selectedTemplateId]
  );

  const table = useTable(
    {
      data,
      columns,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  );

  return (
    <div className={styles.modalTableContainer}>
      <Table striped table={table} isStickyHeader />
    </div>
  );
};
