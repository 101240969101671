import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveModal } from '../../../../../store/modals/modals.selectors';
import { setActiveModal } from '../../../../../store/modals/modals.actions';
import { ModalActionsEnum } from '../../../../document-table/DocumentTableConstants';
import { useUserDealRestrictions } from '../../../../../auth/user-restrictions';
import { useTeamInfo } from '../../../../../team-id-context';
import { useDealId } from '../../../../../deal-id-context';
import DocumentAttachmentsSidebar from './documentAttachmentsSidebar';
import { FileUploadModal } from '../../../../shared/modals';
import { useMutation, useQuery } from '@apollo/client';
import { mutations, queriesV2 } from '../../../../../api';
import { selectLeaseDocumentId, selectLeaseDocumentReceiverTeam, selectLeaseDocumentTeam } from '../../../../../store/lease-document/selectors/lease-document.selectors';

interface DocumentAttachmentsSidebarContainerProps {
  activeUuid: string | null;
}

const DocumentAttachmentsSidebarContainer = ({ activeUuid }: DocumentAttachmentsSidebarContainerProps) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(selectActiveModal);
  
  const { teamId } = useTeamInfo();

  const dealId = useDealId();

  const documentId = useSelector(selectLeaseDocumentId);

  const { canUploadDocument } = useUserDealRestrictions();

  const documentCreatorTeamId = useSelector(selectLeaseDocumentTeam)

  const isUserInCreatorTeam = documentCreatorTeamId === teamId;

  const canUploadExtraAttachments = canUploadDocument && isUserInCreatorTeam

  const handleFileUploadModal = useCallback(() => {
    if (canUploadDocument) {
      return dispatch(setActiveModal(ModalActionsEnum.UPLOAD_ATTACHMENT_FILES));
    } else {
      return dispatch(setActiveModal(null));
    }
  }, [dispatch, canUploadDocument]);

  const { data: documentExtraAttachmentsData } = useQuery(queriesV2.getDocumentExtraAttachments, {
    variables: { documentId: String(documentId)! },
    skip: !documentId,
    fetchPolicy: 'network-only',
  });

  const [removeDocumentExtraAttachment] = useMutation(mutations.removeDocumentExtraAttachment, {
    refetchQueries: [
      {
        query: queriesV2.getDocumentExtraAttachments,
        variables: { documentId: String(documentId)! },
      },
    ],
  });

  const [reorderDocumentExtraAttachments] = useMutation(mutations.reorderDocumentExtraAttachments, {
    refetchQueries: [
      {
        query: queriesV2.getDocumentExtraAttachments,
        variables: { documentId: String(documentId)! },
      },
    ],
  });

  const [attachments, setAttachments] = useState(documentExtraAttachmentsData?.getDocumentById?.extraAttachments);

  const handleRemoveAttachmentClick = async (id: any) => {
    await removeDocumentExtraAttachment({ variables: { documentExtraAttachmentId: String(id) } });
  };

  const handleMoveItem = async (dragIndex: number, hoverIndex: number) => {
    if (!attachments) {
      console.error("Attachments are not available.");
      return;
    }

    // Move the item in the attachments array

    const updatedAttachments = [...attachments];
    const [movedItem] = updatedAttachments.splice(dragIndex, 1);
    updatedAttachments.splice(hoverIndex, 0, movedItem);
  
    // Get the order of every attachment item and store the IDs in an array
    const attachmentIds = updatedAttachments.map(item => item.id);

    // Create a map of attachment IDs to their indices
    const idToIndexMap = new Map(attachmentIds.map((id, index) => [id, index]));

    // Sort updatedAttachments according to the order of attachmentIds
    updatedAttachments.sort((a, b) => {
      const indexA = idToIndexMap.get(a.id);
      const indexB = idToIndexMap.get(b.id);

      if (indexA === undefined || indexB === undefined) {
        return 0; // Keep original order if ID is not found
      }

      return indexA - indexB;
    });

    const orderedAttachmentIds = updatedAttachments.map(item => String(item.id));

    await reorderDocumentExtraAttachments({ 
      variables: { 
        documentId: String(documentId)!, 
        orderedExtraAttachmentIds: orderedAttachmentIds 
      } 
    });

    const reorderedAttachments = updatedAttachments.map((item, index) => ({ ...item, order: index }));
  
    // Update the state with the new order of attachments
    setAttachments(reorderedAttachments);
  };

  useEffect(() => {
    if (documentExtraAttachmentsData) {
      setAttachments(documentExtraAttachmentsData.getDocumentById.extraAttachments);
    }
  }
  , [documentExtraAttachmentsData]);

  

  return (
    <>
      <DocumentAttachmentsSidebar 
        handleFileUploadModal={handleFileUploadModal} 
        handleRemoveAttachmentClick={handleRemoveAttachmentClick}
        attachments={attachments || []} 
        moveItem={handleMoveItem}
        canUpload={canUploadExtraAttachments}
      />
      <FileUploadModal
        onClose={() => dispatch(setActiveModal(null))}
        isOpen={activeModal === ModalActionsEnum.UPLOAD_ATTACHMENT_FILES }
        dealId={dealId}
        teamId={teamId}
      />
    </>
  );
}

export default DocumentAttachmentsSidebarContainer;