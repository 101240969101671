import { ApolloClient, gql } from '@apollo/client';
import { getDocumentById } from '../../api/queries_v2';

type ResponsePayloadType = {
  id: string | number;
  additionalMessage?: string;
};

type ShareDocumentByEmailProps = {
  documentId: string | number | null;
  additionalMessage?: string | null;
  toEmail: string;
  ccEmails?: string[];
  subject: string;
  includeAttachments: boolean;
};

export const shareDocumentByEmail = ({
  documentId,
  additionalMessage,
  toEmail,
  ccEmails,
  subject,
  includeAttachments,
}: ShareDocumentByEmailProps) => (client: ApolloClient<object>): Promise<ResponsePayloadType> => {
  const variables = {
    documentId,
    additionalMessage,
    to: toEmail,
    cc: ccEmails,
    subject,
    confirm: true,
    includeAttachments,
  };

  const mutation = gql`
    mutation shareDocumentByEmail(
      $documentId: ID!, 
      $additionalMessage: String, 
      $cc: [String!], 
      $to: String!, 
      $subject: String!, 
      $confirm: Boolean, 
      $includeAttachments: Boolean
    ) {
      shareDocumentByEmail(
        documentId: $documentId
        additionalMessage: $additionalMessage
        cc: $cc
        to: $to
        subject: $subject
        confirm: $confirm
        includeAttachments: $includeAttachments
      ) {
        success
      }
    }
  `;

  const refetchQueries = [
    {
      query: getDocumentById,
      variables: {
        documentId,
      },
    },
  ];

  return client.mutate({ mutation, variables, refetchQueries }).then(({ data }: Record<any, any>) => {
    return data.shareDocumentByEmail;
  });
};
