import { gql } from '@apollo/client';
import { DEAL_DETAILS_FORM_DATA, TEAM_DATA } from './fragments';
import { graphql } from '../gql';

export const createTeam = graphql(/* GraphQL */`
  mutation createTeam($name: String!, $partyId: ID!) {
    createTeam(name: $name, partyId: $partyId) {
      id
      name
      uuid
      party {
        id
      }
      adminUser {
        id
        email
        uuid
        fullName
      }
    }
  }
`);

// TODO: Move this to a file of its own
export const createExpressDocumentFromTemplate = graphql(/* GraphQL */ `
  mutation createExpressDocumentFromTemplate($dealId: ID, $teamId: ID!, $dealTitle: String, $documentTitle: String!, $templateId: ID!) {
    createExpressDocumentFromTemplate(dealId: $dealId, teamId: $teamId, dealTitle: $dealTitle, documentTitle: $documentTitle, templateId: $templateId) {
      id
      deal {
        id
      }
      team {
        id
      }
    }
  }
`);

export const updateOrganizationRequestExpiry = graphql(/* GraphQL */`
  mutation updateOrganizationRequestExpiry($organizationRequestId: ID!,   $expiresAt: String!) {
    updateOrganizationRequestExpiry(organizationRequestId: $organizationRequestId, expiresAt: $expiresAt) {
      id
      expiresAt
    }
  }
`);

export const createDeal = graphql(/* GraphQL */`
  mutation createDeal($teamId: ID!, $title: String!) {
    createDeal(teamId: $teamId, title: $title) {
      id
      title
    }
  }
`);

export const updateDealDetails = gql`
  mutation updateDealDetails($dealId: ID!, $title: String, $dealDetails: DealDetailsType) {
    updateDealDetails(dealId: $dealId, title: $title, dealDetails: $dealDetails) {
      ...DealDetailsFormData
    }
  }
  ${DEAL_DETAILS_FORM_DATA}
`;

export const updateTeam = graphql(/* GraphQL */`
  mutation updateTeam($id: ID!, $name: String) {
    updateTeam(id: $id, name: $name) {
      id
      name
    }
  }
`);

export const updateUserPermissions = graphql(/* GraphQL */`
  mutation updateUserPermissions($userId: ID!, $teamId: ID!, $permissionUpdates: [PermissionUpdateType!]!) {
    updateUserPermissions(userId: $userId, teamId: $teamId, permissionUpdates: $permissionUpdates) {
      user {
        id
        teamPermissionsByTeam(teamId: $teamId) {
          id
          allowed
          permission {
            id
            displayOrder
            key
            name
            description
          }
        }
      }
    }
  }
`);

export const removeTeamMemberFromTeam = gql`
  mutation removeTeamMemberFromTeam($userId: ID!, $teamId: ID!) {
    removeTeamMemberFromTeam(userId: $userId, teamId: $teamId) {
      ...TeamData
    }
  }
  ${TEAM_DATA}
`;

export const removeTeamMemberFromDeal = graphql(/* GraphQL */`
  mutation removeTeamMemberFromDeal($userId: ID!, $dealId: ID!) {
    removeTeamMemberFromDeal(userId: $userId, dealId: $dealId) {
      id
      dealAccessUsers
    }
  }
`);

export const inviteNewTeamMembersByEmail = gql`
  mutation inviteNewTeamMembersByEmail(
    $teamId: ID!
    $emails: [String!]!
    $userPermissions: [UserPermissionsInputType!]!
    $defaultDeals: [ID!]
    $additionalMessage: String
  ) {
    inviteNewTeamMembersByEmail(
      teamId: $teamId
      emails: $emails
      userPermissions: $userPermissions
      defaultDeals: $defaultDeals
      additionalMessage: $additionalMessage
    ) {
      ...TeamData
    }
  }
  ${TEAM_DATA}
`;

export const replaceImportDocument = graphql(/* GraphQL */`
  mutation replaceImportedDocument(
    $documentId: ID!
    $fileData: BinaryFileInputType!
    $teamId: ID!
  ) {
    replaceImportedDocument(
      documentId: $documentId
      fileData: $fileData
      teamId: $teamId
    ) {
      document
    }
  }
`);

export const replaceImportDocumentTemplate = graphql(/* GraphQL */ `
  mutation replaceImportedDocumentTemplate(
    $templateId: ID!
    $fileData: BinaryFileInputType!
    $teamId: ID!
  ) {
    replaceImportedDocumentTemplate(
      templateId: $templateId
      fileData: $fileData
      teamId: $teamId
    ) {
      template
    }
  }
`);

export const createDocumentTemplate = graphql(/* GraphQL */`
  mutation createDocumentTemplate(
    $teamId: ID!
    $name: String!
    $templateType: String!
    $fileData: BinaryFileInputType
    $restrictedEditing: Boolean
  ) {
    createDocumentTemplate(
      teamId: $teamId
      name: $name
      templateType: $templateType
      restrictedEditing: $restrictedEditing
      fileData: $fileData
    ) {
      id
      name
      template
      team {
        id
      }
      editorConfiguration
    }
  }
`);

export const convertDocxToDocumentTemplate = graphql(/* GraphQL */`
  mutation convertDocxToDocumentTemplate(
    $teamId: ID!
    $name: String!
    $templateType: String!
    $fileData: BinaryFileInputType!
    $restrictedEditing: Boolean
  ) {
    convertDocxToDocumentTemplate(
      teamId: $teamId
      name: $name
      templateType: $templateType
      restrictedEditing: $restrictedEditing
      fileData: $fileData
    ) {
      id
      name
      template
      team {
        id
      }
      editorConfiguration
    }
  }
`);

export const updateDocumentTemplate = graphql(/* GraphQL */`
  mutation updateDocumentTemplate($documentTemplateId: ID!, $name: String, $template: Json, $hidden: Boolean) {
    updateDocumentTemplate(
      documentTemplateId: $documentTemplateId
      name: $name
      hidden: $hidden
      template: $template
    ) {
      id
      name
      template
      updatedAt
      team {
        id
      }
    }
  }
`);

export const resendInvitation = graphql(/* GraphQL */`
  mutation resendInvitation($invitationId: ID!) {
    resendInvitation(invitationId: $invitationId) {
      success
    }
  }
`);

export const cancelInvitation = graphql(/* GraphQL */`
  mutation cancelInvitation($invitationId: ID!) {
    revokeInvitation(invitationId: $invitationId) {
      id
      status
    }
  }
`);

export const updatePendingInvitation = graphql(/* GraphQL */`
    mutation updatePendingInvitation($id: ID!, $permissionIds: [ID!]!, $dealIds: [ID!]!) {
      updatePendingInvitation(id: $id, permissionIds: $permissionIds, dealIds: $dealIds) {
        id
        status
      }
    }
`);

export const updateUserPassword = graphql(/* GraphQL */`
  mutation updateUserPassword($password: String!, $passwordConfirmation: String!, $currentPassword: String!) {
    updateUserPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      currentPassword: $currentPassword
    ) {
      success
    }
  }
`);

export const updateUser = graphql(/* GraphQL */`
  mutation updateUser($fullName: String, $companyName: String) {
    updateUser(fullName: $fullName, companyName: $companyName) {
      currentUser {
        id
        fullName
        companyName
      }
      success
    }
  }
`);

export const cloneDocumentTemplate = graphql(/* GraphQL */`
  mutation cloneDocumentTemplate($documentTemplateId: ID!) {
    cloneDocumentTemplate(documentTemplateId: $documentTemplateId) {
      id
      name
      country
      state
      template
      hidden
    }
  }
`);

export const createDocumentImage = graphql(/* GraphQL */`
  mutation createDocumentImage($documentId: ID!, $fileData: BinaryFileInputType!) {
    createDocumentImage(documentId: $documentId, fileData: $fileData) {
      success
      path
    }
  }
`);

export const createDocumentTemplateImage = graphql(/* GraphQL */`
  mutation createDocumentTemplateImage($documentTemplateId: ID!, $fileData: BinaryFileInputType!) {
    createDocumentTemplateImage(documentTemplateId: $documentTemplateId, fileData: $fileData) {
      success
      path
    }
  }
`);

export const lockDocument = graphql(/* GraphQL */`
  mutation lockDocument($documentId: ID!, $userId: ID!) {
    lockDocument(documentId: $documentId, userId: $userId) {
      documentId
      userId
      type
      lockedAt
    }
  }
`);

export const unlockDocument = graphql(/* GraphQL */`
  mutation unlockDocument($documentId: ID!, $userId: ID!, $sessionActivities: [String]!) {
    unlockDocument(documentId: $documentId, userId: $userId, sessionActivities: $sessionActivities) {
      documentId
      userId
      type
      lockedAt
    }
  }
`);

export const lockDocumentTemplate = graphql(/* GraphQL */`
  mutation lockDocumentTemplate($documentId: ID!, $userId: ID!) {
    lockDocumentTemplate(documentId: $documentId, userId: $userId) {
      documentId
      userId
      type
      lockedAt
    }
  }
`);

export const unlockDocumentTemplate = graphql(/* GraphQL */`
  mutation unlockDocumentTemplate($documentId: ID!, $userId: ID!) {
    unlockDocumentTemplate(documentId: $documentId, userId: $userId) {
      documentId
      userId
      type
      lockedAt
    }
  }
`);

export const createDocumentPlaceholder = graphql(/* GraphQL */`
  mutation createDocumentPlaceholder(
    $isRequired: Boolean!,
    $linkableId: ID!,
    $linkableType: String!,
    $teamId: ID!,
    $title: String!
  ) {
    createDocumentPlaceholder(
      isRequired: $isRequired,
      linkableId: $linkableId,
      linkableType: $linkableType,
      teamId: $teamId,
      title: $title
    ) {
      id
      createdAt
      isRequired
      status
      team {
        id
      }
      title
      updatedAt
      uuid
    }
  }
`);

export const removeDocumentPlaceholder = graphql(/* GraphQL */`
  mutation removeDocumentPlaceholder(
    $linkableId: ID!,
    $linkableType: String!,
    $placeholderUuid: ID!,
    $teamId: ID!
  ) {
    removeDocumentPlaceholder(
      linkableId: $linkableId,
      linkableType: $linkableType,
      placeholderUuid: $placeholderUuid,
      teamId: $teamId
    ) {
      success
    }
  }
`);

export const updateDocumentPlaceholder = graphql(/* GraphQL */`
  mutation updateDocumentPlaceholder(
    $isRequired: Boolean,
    $linkableId: ID!,
    $linkableType: String!,
    $placeholderUuid: ID!,
    $teamId: ID!,
    $status: String,
    $title: String
  ) {
    updateDocumentPlaceholder(
      isRequired: $isRequired,
      linkableId: $linkableId,
      linkableType: $linkableType,
      placeholderUuid: $placeholderUuid,
      teamId: $teamId,
      status: $status,
      title: $title
    ) {
      id
      createdAt
      isRequired
      status
      team {
        id
      }
      title
      updatedAt
      uuid
    }
  }
`);

export const createDocumentSuggestion = graphql(/* GraphQL */`
  mutation createDocumentSuggestion(
    $documentId: ID!
    $documentType: String!
    $type: String!
    $data: Json!
    $attributes: Json!
    $externalId: ID!
    $originalSuggestionExternalId: ID
  ) {
    createDocumentSuggestion(
      documentId: $documentId
      documentType: $documentType
      type: $type
      data: $data
      attributes: $attributes
      originalSuggestionExternalId: $originalSuggestionExternalId
      externalId: $externalId
    ) {
      createdAt
      authorRole
    }
  }
`);

export const syncDocumentSuggestions = graphql(/* GraphQL */`
  mutation syncDocumentSuggestions($documentId: ID!, $documentType: String!, $externalIds: [ID!]!) {
    syncDocumentSuggestions(documentId: $documentId, documentType: $documentType, externalIds: $externalIds) {
      id
    }
  }
`);

export const deleteDocumentSuggestionBySuggestionId = graphql(/* GraphQL */`
  mutation deleteDocumentSuggestionBySuggestionId($documentSuggestionExternalId: ID!) {
    deleteDocumentSuggestion(documentSuggestionExternalId: $documentSuggestionExternalId) {
      success
    }
  }
`);

export const acceptDocumentSuggestionBySuggestionId = graphql(/* GraphQL */`
  mutation acceptDocumentSuggestionBySuggestionId($documentSuggestionExternalId: ID!, $data: Json!, $attributes: Json!) {
    acceptDocumentSuggestion(
      documentSuggestionExternalId: $documentSuggestionExternalId
      data: $data
      attributes: $attributes
    ) {
      success
    }
  }
`);

export const rejectDocumentSuggestionBySuggestionId = graphql(/* GraphQL */`
  mutation rejectDocumentSuggestionBySuggestionId($documentSuggestionExternalId: ID!, $data: Json!, $attributes: Json!) {
    rejectDocumentSuggestion(
      documentSuggestionExternalId: $documentSuggestionExternalId
      data: $data
      attributes: $attributes
    ) {
      success
    }
  }
`);

export const pendDocumentSuggestionBySuggestionId = graphql(/* GraphQL */`
  mutation pendDocumentSuggestionBySuggestionId($documentSuggestionExternalId: ID!) {
    pendDocumentSuggestion(documentSuggestionExternalId: $documentSuggestionExternalId) {
      success
    }
  }
`);

export const updateDocumentRevisions = graphql(/* GraphQL */`
  mutation updateDocumentRevisions($documentId: ID!, $documentType: String!, $data: [CkeRevisionInputType!]!) {
    updateDocumentRevisions(documentId: $documentId, documentType: $documentType, data: $data) {
      createdAt
      id
      revisionId
      attributes
      name
      creatorId
      toVersion
      fromVersion
    }
  }
`);

export const createDocumentSigner = graphql(/* GraphQL */`
  mutation CreateDocumentSigner($documentId: ID!, $email: String!, $order: Int!) {
    createSigner(documentId: $documentId, email: $email, order: $order) {
      id
      email
      order
    }
  }
`);

export const deleteDocumentSigner = graphql(/* GraphQL */`
  mutation DeleteDocumentSigner($signerId: ID!) {
    deleteSigner(signerId: $signerId) {
      id
      order
    }
  }
`);

export const updateDocumentCopyRecipients = graphql(/* GraphQL */`
  mutation UpdateDocumentCopyRecipientsList($documentId: ID!, $recipients: [String]!) {
    updateDocumentCopyRecipientsList(documentId: $documentId, recipients: $recipients) {
      id
      ccRecipients
    }
  }
`);

export const updateDocumentSigner = graphql(/* GraphQL */`
  mutation UpdateDocumentSigner($signerId: ID!, $email: String!) {
    updateSigner(signerId: $signerId, email: $email) {
      id
      email
    }
  }
`);

export const bulkUpdateSigners = graphql(/* GraphQL */`
  mutation BulkUpdateSigners($documentId: ID!, $signers: [SignerInputType!]!) {
    bulkUpdateSigners(documentId: $documentId, signers: $signers) {
      id
      email
      order
    }
  }
`);

export const prepareToSignDocument = graphql(/* GraphQL */`
  mutation PrepareToSignDocument($documentId: ID!, $additionalMessage: String) {
    prepareToSignDocument(documentId: $documentId, additionalMessage: $additionalMessage, confirm: true) {
      id
      title
      status
    }
  }
`);

export const approveDocumentReview = graphql(/* GraphQL */`
  mutation ApproveDocumentReview($reviewId: ID!, $reason: String) {
    approveDocumentReview(reviewId: $reviewId, reason: $reason) {
      id
      approvedAt
    }
  }
`);

export const rejectDocumentReview = graphql(/* GraphQL */`
  mutation RejectDocumentReview($reviewId: ID!, $reason: String) {
    rejectDocumentReview(reviewId: $reviewId, reason: $reason) {
      id
      rejectedAt
    }
  }
`);

export const cancelDocumentApprovalRequest = graphql(/* GraphQL */`
  mutation CancelDocumentApprovalRequest($requestId: ID!, $additionalMessage: String) {
    cancelDocumentApprovalRequest(requestId: $requestId, additionalMessage: $additionalMessage) {
      id
    }
  }
`);

export const unlockDocumentAfterApproval = graphql(/* GraphQL */`
  mutation UnlockDocumentAfterApproval($documentId: ID!, $teamId: ID!) {
    unlockDocumentAfterApproval(documentId: $documentId, teamId: $teamId) {
      id
    }
  }
`);

export const saveAnnotationsXfDfLayer = graphql(/* GraphQL */`
  mutation SaveSignaturesLayerForDocument($documentId: ID!, $xfdfString: String!) {
    saveSignaturesLayerForDocument(documentId: $documentId, xfdfString: $xfdfString) {
      id
      title
      signaturesXfdfUrl
      lastUpdated
    }
  }
`);

export const saveCommentsLayerForDocument = graphql(/* GraphQL */`
  mutation SaveCommentsLayerForDocument($documentId: ID!, $xfdfString: String!) {
    saveCommentsLayerForDocument(documentId: $documentId, xfdfString: $xfdfString) {
      id
      title
      commentsXfdfUrl
      lastUpdated
    }
  }
`);

export const saveInputLayerForDocument = graphql(/* GraphQL */`
  mutation SaveInputLayerForDocument($documentId: ID!, $xfdfString: String!) {
    saveInputLayerForDocument(documentId: $documentId, xfdfString: $xfdfString) {
      id
      title
      inputXfdfUrl
      lastUpdated
    }
  }
`);

export const saveCommentsLayerForDocumentTemplate = graphql(/* GraphQL */`
  mutation SaveCommentsLayerForDocumentTemplate($documentTemplateId: ID!, $xfdfString: String!) {
    saveCommentsLayerForDocumentTemplate(documentTemplateId: $documentTemplateId, xfdfString: $xfdfString) {
      id
      commentsXfdfUrl
    }
  }
`);

export const saveInputLayerForDocumentTemplate = graphql(/* GraphQL */`
  mutation SaveInputLayerForDocumentTemplate($documentTemplateId: ID!, $xfdfString: String!) {
    saveInputLayerForDocumentTemplate(documentTemplateId: $documentTemplateId, xfdfString: $xfdfString) {
      id
      inputXfdfUrl
    }
  }
`);

export const cancelPreparingToSignDocument = graphql(/* GraphQL */`
  mutation CancelPreparingToSignDocument($documentId: ID!, $additionalMessage: String) {
    cancelPreparingToSignDocument(documentId: $documentId, additionalMessage: $additionalMessage) {
      id
      status
      document
      title
      lastUpdated
      eversignStatus
      receiverTeam {
        id
      }
      team {
        id
      }
      deal {
        id
      }
    }
  }
`);

export const createOrganizationRequest = graphql(/* GraphQL */`
  mutation CreateOrganizationRequest(
    $organizationId: ID!
    $requesterId: ID!
    $requesterType: String!
    $membershipId: String
  ) {
    createOrganizationRequest(
      organizationId: $organizationId
      requesterId: $requesterId
      requesterType: $requesterType
      membershipId: $membershipId
    ) {
      id
    }
  }
`);

export const approveOrganizationRequest = graphql(/* GraphQL */`
  mutation approveOrganizationRequest($organizationRequestId: ID!) {
    approveOrganizationRequest(organizationRequestId: $organizationRequestId) {
      id
    }
  }
`);

export const rejectOrganizationRequest = graphql(/* GraphQL */`
  mutation rejectOrganizationRequest($organizationRequestId: ID!) {
    rejectOrganizationRequest(organizationRequestId: $organizationRequestId) {
      id
    }
  }
`);

export const removeOrganizationRequest = graphql(/* GraphQL */`
  mutation removeOrganizationRequest($organizationRequestId: ID!) {
    removeOrganizationRequest(organizationRequestId: $organizationRequestId) {
      success
    }
  }
`);

export const toggleReceiverCollaborationForDocument = graphql(/* GraphQL */`
  mutation toggleReceiverCollaborationForDocument($documentId: ID!, $flagState: Boolean!) {
    toggleReceiverCollaborationForDocument(documentId: $documentId, flagState: $flagState) {
      receiverCollaborationEnabled
    }
  }
`);

export const updateDocumentEditorConfiguration = graphql(/* GraphQL */`
    mutation updateDocumentEditorConfiguration($documentId: ID!, $configuration: EditorConfigurationInputType!) {
      updateDocumentEditorConfiguration(documentId: $documentId, configuration: $configuration) {
        id
        editorConfiguration
      }
    }
`);

export const updateTemplateEditorConfiguration = graphql(/* GraphQL */`
  mutation updateTemplateEditorConfiguration($templateId: ID!, $configuration: EditorConfigurationInputType!) {
    updateTemplateEditorConfiguration(templateId: $templateId, configuration: $configuration) {
      id
      editorConfiguration
    }
  }
`);

export const updateUserCommunicationPreference = graphql(/* GraphQL */`
  mutation updateUserCommunicationPreference($preferences: CommunicationPreferencesInputType!) {
    updateUserCommunicationPreference(preferences: $preferences) {
      communicationPreferences
    }
  }
`);


export const uploadDocumentExtraAttachments = graphql(/* GraphQL */`
  mutation uploadDocumentExtraAttachments(
    $documentId: ID!
    $extraAttachments: [ExtraAttachmentInputType!]!
  ) {
    uploadDocumentExtraAttachments(
      documentId: $documentId
      extraAttachments: $extraAttachments
    ) {
      extraAttachments {
        id
        attachment
        order
        user {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`);

export const removeDocumentExtraAttachment = graphql(/* GraphQL */`
  mutation removeDocumentExtraAttachment(
    $documentExtraAttachmentId: ID!
  ) {
    removeDocumentExtraAttachment(
      documentExtraAttachmentId: $documentExtraAttachmentId
    ) {
      extraAttachments {
        id
        attachment
        order
        user {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`);

export const reorderDocumentExtraAttachments = graphql(/* GraphQL */`
  mutation reorderDocumentExtraAttachments(
    $documentId: ID!
    $orderedExtraAttachmentIds: [ID!]!
  ) {
    reorderDocumentExtraAttachments(
      documentId: $documentId
      orderedExtraAttachmentIds: $orderedExtraAttachmentIds
    ) {
      extraAttachments {
        id
        attachment
        order
        user {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`);
