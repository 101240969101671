import React, { useCallback, useEffect } from 'react';
import { useDispatch as reduxUseDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useApolloClient as apolloUseApolloClient, useMutation, useQuery } from '@apollo/client';
import { useLeaseDocumentTeamPermissions } from '../shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { getTeamsForUser } from '../../../utils/api/get-teams-for-user/get-teams-for-user';
import { setAllTeams, setOwnedTeams } from '../../../store/permissions/permissions.actions';
import {
  dispatchEraseLeaseDocumentValuesFromStore,
  dispatchLeaseDocumentResponseToStore,
} from '../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { TemplatePage } from './TemplatePage';
import { getOrganizationsTemplatesByUser, getDocumentTemplateById as getDocumentTemplateByIdQuery } from '../../../api/queries_v2';
import LoadingSpinner from '../../../components/loading-spinner';
import { useNotifications } from '../../../notifications';
import '../shared/lease-document-wizard-page.scss';
import { TemplateType } from '../../../consts/forms-types';
import { useLeaseDocumentTemplateSave } from '../shared/hooks/use-lease-document-save/use-lease-document-save.hook';
import { updateDocumentTemplateContentOrVisibilityOrName } from '../../../utils/api/update-lease-document-offer/update-document';
import { useIntl } from 'react-intl';
import {
  selectLeaseDocumentIsPending,
  selectLeaseDocumentTemplate,
  selectLeaseDocumentTitle,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { setDeal } from '../../../store/deals/deals.actions';
import { getDocumentTemplateById } from '../../../utils/api/get-template-by-id';
import {
  useLeaseDocumentTemplateTitleSave,
} from '../shared/hooks/use-lease-document-title-save/use-lease-document-title-save.hook';


type TemplatePageContainerProps = {
  routeParams?: { templateId: string; teamId: string };
  useDispatch?: (...args: any[]) => any;
  useApolloClient?: (...args: any[]) => any;
  isOrganizationTemplate?: boolean;
};

export const TemplatePageContainer = ({
  useDispatch = reduxUseDispatch,
  useApolloClient = apolloUseApolloClient,
  isOrganizationTemplate,
}: TemplatePageContainerProps) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  // const [updateDocumentTemplate] = useMutation(mutations.updateDocumentTemplate);
  //@ts-ignore TODO: update useNotifications to TS
  const [, { error: notifyError }] = useNotifications();
  const reactIntl = useIntl();
  const { templateId, teamId } = useParams();
  const dispatch = useDispatch();
  const dealTemplatesUrl = `/teams/${teamId}/templates`;
  const dealClausesUrl = `/teams/${teamId}/clauses`;
  const template = useSelector(selectLeaseDocumentTemplate);
  const isPending = useSelector(selectLeaseDocumentIsPending);

  useEffect(
    function cleanUpOnMount() {
      dispatchEraseLeaseDocumentValuesFromStore(dispatch);
    },
    [dispatch]
  );

  useEffect(
    function fetchTeamsForUserOnMount() {
      getTeamsForUser()(apolloClient).then(({ ownedTeams, allTeams }) => {
        dispatch(setOwnedTeams(ownedTeams));
        dispatch(setAllTeams(allTeams));
      });
    },
    [apolloClient, dispatch]
  );

  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });
  const canEditDocument = permissions.hasEditPermission;
  const canCommentDocument = permissions.hasCommentPermission;

  const { loading: isDocumentTemplateByIdDataLoading, data: documentTemplateByIdData } = useQuery(
    getDocumentTemplateByIdQuery,
    {
      fetchPolicy: 'network-only',
      skip: !templateId || isOrganizationTemplate,
      variables: {
        documentTemplateId: templateId,
      },
    }
  );

  const { loading: isUserOrgsTemplatesDataLoading, data: userOrgsTemplatesData } = useQuery(
    getOrganizationsTemplatesByUser,
    {
      fetchPolicy: 'network-only',
      skip: !teamId || !isOrganizationTemplate,
      variables: {
        teamId,
      },
    }
  );

  const handleError = useCallback(() => {
    notifyError(
      reactIntl.formatMessage({
        id: 'lease-document-template-wizard.fetch-error',
        defaultMessage: 'Unable to fetch the Template',
      })
    );
  }, [notifyError, reactIntl]);

  const handleTemplateLoadError = useCallback(
    errorCode => {
      if (errorCode === 'unauthorized-query-access') {
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (template !== null) {
      return;
    }

    if (!templateId) {
      return;
    }

    getDocumentTemplateById(templateId)(apolloClient)
      .then(response => {
        dispatch(setDeal(response.deal));
        // @ts-ignore
        dispatchLeaseDocumentResponseToStore(dispatch, response);
      })
      .catch(err => {
        if (typeof handleTemplateLoadError === 'function') {
          handleTemplateLoadError(err);
        }

        handleError();
      });
  }, [apolloClient, dispatch, handleError, template, templateId, handleTemplateLoadError]);

  const currentOrgTemplateData = userOrgsTemplatesData?.organizationsTemplatesByUser.find(
    (template: any) => template.id === templateId
  );

  const handleSaveError = useCallback(() => {
    notifyError(
      reactIntl.formatMessage({
        id: 'shared-offer-page.save-error',
        defaultMessage: 'Unable to save the Document',
      })
    );
  }, [notifyError, reactIntl]);

  const handleSave = useLeaseDocumentTemplateSave({
    id: templateId!,
    save: updateDocumentTemplateContentOrVisibilityOrName,
    onError: handleSaveError,
    apolloClient,
  })

  const saveTitle = useLeaseDocumentTemplateTitleSave({
    id: templateId!,
    save: updateDocumentTemplateContentOrVisibilityOrName,
    onError: handleSaveError,
    apolloClient,
  })

  const handleTitleSave = useCallback(
    title => {
      if (isPending) {
        return;
      }

      saveTitle(title);
    },
    [isPending, saveTitle]
  );

  const handleTemplateClose = useCallback(() => {
    const currentTemplateData = isOrganizationTemplate
      ? currentOrgTemplateData
      : documentTemplateByIdData?.getDocumentTemplateById;

    const closeDocumentLink = currentTemplateData?.templateType === TemplateType.CLAUSE
        ? dealClausesUrl
        : dealTemplatesUrl;

    navigate(closeDocumentLink);
  }, [isOrganizationTemplate, currentOrgTemplateData, documentTemplateByIdData, dealClausesUrl, dealTemplatesUrl, navigate]);

  const storedTitle = useSelector(selectLeaseDocumentTitle);

  const isTemplateLoading = (!documentTemplateByIdData && isDocumentTemplateByIdDataLoading) ||
    !template ||
    isUserOrgsTemplatesDataLoading ||
    !templateId

  if (isTemplateLoading) {
    return <LoadingSpinner />;
  }

  return (
    <TemplatePage
      canEdit={canEditDocument}
      canComment={canCommentDocument}
      onSave={!isOrganizationTemplate ? handleSave : () => {}}
      documentId={templateId}
      teamId={teamId}
      permissions={permissions}
      onTitleSave={handleTitleSave}
      onTemplateClose={handleTemplateClose}
      documentTemplateData={
        !isOrganizationTemplate && documentTemplateByIdData
          ? documentTemplateByIdData.getDocumentTemplateById
          : currentOrgTemplateData
      }
      isOrganizationTemplate={isOrganizationTemplate}
      templateId={templateId}
      template={template}
      templateTitle={storedTitle || ""}
      lastUpdated={documentTemplateByIdData?.getDocumentTemplateById?.updatedAt}
      isTemplateLoaded={!isTemplateLoading}
    />
  );
};
