import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactMultiEmail } from 'react-multi-email';
import { FormControl, FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import SubmitButton from '../../../../../../submit-button';
import { useNotifications } from '../../../../../../../notifications';
import { OptionalMessageInputRef } from '../../../../../lease-document-wizard-modal/custom-modals/lease-document-wizard-action-modal/OptionalMessageInputRef';
import {
  selectLeaseDocumentId,
  selectLeaseDocumentTitle,
} from '../../../../../../../store/lease-document/selectors/lease-document.selectors';
import { shareDocumentByEmail } from '../../../../../../../utils/api/share-document-by-email';
import { fillPlaceholders, paths } from '../../../../../../../routing';
import { EMAIL_VALIDATION_REGEX } from '../../../../../../../shared/constants/emailValidation';
import { TextField } from '../../../../../../fields';
import '../../../../../../../forms/multiEmailStyles.scss';
import { useTotango } from "../../../../../../../shared/hooks/useTotango";
import { TOTANGO_EVENT_TYPES } from "../../../../../../../utils/totango";
import styles from '../RequestReview.module.scss';
import Tooltip from '../../../../../../tooltip';
import { INCLUDE_ATTACHMENTS_TOOLTIP_SIZE_WARNING, INCLUDE_ATTACHMENTS_TOOLTIP } from '../../../../../lease-editor/constants/EditorConstats';
import { Checkbox } from '../../../../../../checkbox-or-radio-field';
import classNames from 'classnames';

interface RequestReviewViaEmailFormProps {
  bodyContainer: React.ComponentType | keyof React.ReactHTML;
  actionsContainer: React.ComponentType | keyof React.ReactHTML;
  renderSecondaryAction: () => {};
  onSuccess: () => void;
  teamId: string;
  dealId: string;
  enableAttachments: boolean;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required()
    .matches(EMAIL_VALIDATION_REGEX, 'Please provide correct email'),
});

const RequestReviewViaEmailForm = ({
  bodyContainer: BodyContainer = React.Fragment,
  actionsContainer: ActionsContainer = React.Fragment,
  renderSecondaryAction,
  onSuccess,
  teamId,
  dealId,
  enableAttachments,
}: RequestReviewViaEmailFormProps) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [ccEmails, setCCEmails] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSubject, setEmailSubject] = useState<string | null>(null);
  const [includeAttachments, setIncludeAttachments] = useState<boolean>(enableAttachments);
  const [sendInvitationButtonDisabled, setSendInvitationButtonDisabled] = useState(true);
  const documentTitle = useSelector(selectLeaseDocumentTitle);
  const documentId = useSelector(selectLeaseDocumentId);
  const optionalMessageInputRef = React.useRef<HTMLTextAreaElement>(null);
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const placeholderMessage = `${documentTitle} - please review`;
  const includeAttachmentsRef = useRef(includeAttachments);
  const sendTotango = useTotango();

  useEffect(() => {
    setSendInvitationButtonDisabled(false);
  }, []);

  useEffect(() => {
    includeAttachmentsRef.current = includeAttachments;
  }, [includeAttachments]);

  const onSubmitClick = useCallback(
    async values => {
      const additionalMessage = optionalMessageInputRef.current?.value;

      setIsSubmitting(true);

      const variables = {
        documentId,
        ccEmails,
        toEmail: values?.email,
        subject: !!emailSubject?.trim() ? emailSubject : placeholderMessage,
        additionalMessage,
        includeAttachments: includeAttachmentsRef.current,
        confirm: true
      };

      shareDocumentByEmail(variables)(apolloClient)
        .then(() => {
          sendTotango(TOTANGO_EVENT_TYPES.docSentViaEmail);
          onSuccess();
          navigate(fillPlaceholders(paths.deals.detail, { teamId, dealId }));

          notifySuccess({
            message: {
              id: 'phrase.invitation.success',
              defaultMessage: '{documentTitle} has been sent to {email}',
              values: {
                email: values?.email,
                documentTitle,
              },
            },
          });
        })
        .catch(error => {
          notifyError({ message: error.message });
        });
    },
    [
      sendTotango,
      apolloClient,
      ccEmails,
      dealId,
      documentId,
      documentTitle,
      emailSubject,
      notifyError,
      notifySuccess,
      onSuccess,
      teamId,
      placeholderMessage,
      navigate,
    ]
  );

  const handleSend = useCallback(
    async values => {
      const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

      if (!documentPendingActionsPlugin) {
        return onSubmitClick(values);
      }

      if (!documentPendingActionsPlugin.hasAny) {
        return onSubmitClick(values);
      }

      documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
        if (!documentPendingActionsPlugin.hasAny) {
          event.off();
          event.stop();
          return onSubmitClick(values);
        }
      });
    },
    [onSubmitClick]
  );

  const handleIncludeAttachmentsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIncludeAttachments(e.currentTarget.checked);
  }

  return (
    <Formik
      validateOnBlur
      initialValues={{
        email: '',
      }}
      onSubmit={values => {
        handleSend(values);
      }}
      validationSchema={validationSchema}
    >
      {({ isValid }) => {
        return (
          <Form>
            <BodyContainer>
              <p>
                <FormattedMessage
                  id="phrase.invite-team-member.instructions"
                  defaultMessage="Responses to this email will appear in the Deal Activity feed"
                />
              </p>

              <div className={styles.emailInputField}>
                <TextField
                  //@ts-ignore TODO update TextField to ts
                  id="inviteReceivingTeamForm.email"
                  name="email"
                  type="email"
                  label="To:"
                />
              </div>

              <label
                className="text-muted font-weight-bold form-label mt-1"
                htmlFor="requestReviewViaEmailForm.emailCC"
              >
                CC:
              </label>
              <ReactMultiEmail
                emails={ccEmails}
                onChange={_emails => {
                  //@ts-ignore
                  setCCEmails(_emails);
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              ></ReactMultiEmail>
              <label
                className="text-muted font-weight-bold form-label mt-1"
                htmlFor="requestReviewViaEmailForm.emailTo"
              >
                Subject:
              </label>
              <FormControl
                id="requestReviewViaEmailForm.subject"
                data-testid="requestReviewViaEmailForm.subject-field"
                name="requestReviewViaEmailForm.subject"
                type="text"
                placeholder={placeholderMessage}
                value={emailSubject || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailSubject(e.currentTarget.value)}
                onFocus={(evt: any) => {
                  evt.currentTarget.placeholder = '';
                }}
                onBlur={(evt: any) => {
                  evt.currentTarget.placeholder = placeholderMessage;
                }}
              ></FormControl>
              <OptionalMessageInputRef ref={optionalMessageInputRef} />
              <div className="mt-2 text-muted">&#128206; {`${documentTitle}.pdf`}</div>
              <FormGroup className={styles.includeAttachmentsFormGroup}>
                <FormControl
                  id="requestReviewViaEmailForm.attachments"
                  data-testid="requestReviewViaEmailForm.attachments-field"
                  name="requestReviewViaEmailForm.attachments"
                  type="checkbox"
                  className={styles.includeAttachmentsCheckbox}
                  checked={includeAttachments && enableAttachments}
                  onChange={handleIncludeAttachmentsChange}
                  disabled={!enableAttachments}
                ></FormControl> 

                <label
                  className={classNames(styles.includeAttachmentsLabel, "text-muted font-weight-light form-label mt-2")}
                  htmlFor="requestReviewViaEmailForm.attachments"
                >
                  Include document attachments
                  <Tooltip testId="send-via-email-pdf-size-tooltip" placement="bottom">
                    {enableAttachments ? INCLUDE_ATTACHMENTS_TOOLTIP : INCLUDE_ATTACHMENTS_TOOLTIP_SIZE_WARNING}
                  </Tooltip>
                </label>
              </FormGroup>
            </BodyContainer>
            <ActionsContainer>
              {renderSecondaryAction?.()}
              <SubmitButton
                label={<FormattedMessage id="cta.send-invitation" defaultMessage="Send" />}
                disabled={isSubmitting || sendInvitationButtonDisabled || !isValid}
                isSubmitting={isSubmitting}
              />
            </ActionsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestReviewViaEmailForm;
