import React, { useCallback, useState } from 'react';
import FileUploadModal from './FileUploadModal';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { queriesV2 } from '../../../../api';
import { useNotifications } from '../../../../notifications';
import { createDocumentTemplate, convertDocxToDocumentTemplate, uploadDocumentExtraAttachments } from '../../../../api/mutations';
import { TemplateType } from '../../../../consts/forms-types';
import { fillPlaceholders, useNavigate, paths } from '../../../../routing';
import { dispatchLeaseDocumentResponseToStore } from '../../../../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { ModalActionsEnum } from '../../../document-table/DocumentTableConstants';
import { selectActiveModal } from '../../../../store/modals/modals.selectors';
import { graphql } from '../../../../gql';
import {
  ConvertDocxToDocumentMutation,
  ConvertDocxToDocumentTemplateMutation,
  CreateDocumentTemplateMutation,
  CreateImportedDocumentMutation,
} from '../../../../gql/graphql';
import { useTotango } from '../../../../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../../../../utils/totango';
import { TrackChangesModal } from '../trackChangesModal';
import { selectLeaseDocumentId } from '../../../../store/lease-document/selectors/lease-document.selectors';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dealId?: string;
  teamId: string;
  templateUpload?: boolean;
  allowMultiple?: boolean;
}

const CREATE_IMPORTED_DOCUMENT = graphql(/* GraphQL */ `
  mutation createImportedDocument($dealId: ID!, $teamId: ID!, $title: String!, $fileData: BinaryFileInputType!) {
    createImportedDocument(dealId: $dealId, teamId: $teamId, title: $title, fileData: $fileData) {
      id
      status
      currentTeamPossession
      title
      lastUpdated
      eversignPdfUrl
      shouldReturnForReceiverReview
      source
      importedAssetUrl
      importedAssetContentType
      generatedDocumentPdfUrl
    }
  }
`);

const CREATE_IMPORTED_DOCX_DOCUMENT = graphql(/* GraphQL */ `
  mutation convertDocxToDocument(
    $dealId: ID!
    $teamId: ID!
    $title: String!
    $fileData: BinaryFileInputType!
    $configuration: EditorConfigurationInputType
  ) {
    convertDocxToDocument(
      dealId: $dealId
      teamId: $teamId
      title: $title
      fileData: $fileData
      configuration: $configuration
    ) {
      id
      status
      currentTeamPossession
      title
      lastUpdated
      eversignPdfUrl
      shouldReturnForReceiverReview
      source
      importedAssetUrl
      importedAssetContentType
      generatedDocumentPdfUrl
    }
  }
`);

export type UploadFileData = {
  documentTitle: string;
  selectedFile: {
    fileName: string | null;
    fileData: string | ArrayBuffer | null;
    contentType: string;
  };
  isTrackChanges?: boolean;
  isRestrictedEditingEnabled?: boolean;
};

const FileUploadModalContainer = ({ isOpen, onClose, dealId, teamId, templateUpload, allowMultiple = false }: Props) => {
  const [, { error: notifyError }] = useNotifications();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeModal = useSelector(selectActiveModal);
  const isDocxUploadModal = activeModal === ModalActionsEnum.UPLOAD_DOC_FILE;
  const isDocAttachments = activeModal === ModalActionsEnum.UPLOAD_ATTACHMENT_FILES;

  const documentId = useSelector(selectLeaseDocumentId);

  const [createUploadedDocument, { loading: createUploadedDocumentLoading }] = useMutation(CREATE_IMPORTED_DOCUMENT, {
    refetchQueries: [
      {
        query: queriesV2.getDocumentsByDeal,
        variables: {
          dealId,
        },
      },
    ],
  });

  const sendTotango = useTotango();

  const [isTrackChangesModal, setIsTrackChangesModal] = useState(false);

  const [uploadFileData, setUploadFileData] = useState<UploadFileData | null>(null);

  const [createUploadedDocxDocument, { loading: createUploadedDocxDocumentLoading }] = useMutation(
    CREATE_IMPORTED_DOCX_DOCUMENT,
    {
      refetchQueries: [
        {
          query: queriesV2.getDocumentsByDeal,
          variables: {
            dealId,
          },
        },
      ],
    }
  );

  const [convertDocxToDocumentTemplateMutation, { loading: convertDocxToDocumentTemplateLoading }] = useMutation(
    convertDocxToDocumentTemplate,
    {
      refetchQueries: [
        {
          query: queriesV2.getDocumentTemplates,
          variables: {
            teamId,
          },
        },
      ],
    }
  );

  const [createDocumentTemplateMutation, { loading: createDocumentTemplateLoading }] = useMutation(
    createDocumentTemplate,
    {
      refetchQueries: [
        {
          query: queriesV2.getDocumentTemplates,
          variables: {
            teamId,
          },
        },
      ],
    }
  );

  const [uploadDocumentExtraAttachmentMutation, { loading: uploadDocumentExtraAttachmentsLoading }] = useMutation(
    uploadDocumentExtraAttachments,
    {
      refetchQueries: [
        {
          query: queriesV2.getDocumentExtraAttachments,
          variables: { documentId },
        },
      ],
    }
  );

  const onUploadFileData = async ({ documentTitle, selectedFile, isRestrictedEditingEnabled }: UploadFileData) => {
    if (isDocxUploadModal && !templateUpload) {
      await uploadDocument({ documentTitle, selectedFile, isTrackChanges: true });
    } else if (isDocAttachments) {
      const uploadVariables = {
        extraAttachments: [
          {
            file: {
              filename: documentTitle.length > 0 ? documentTitle : selectedFile.fileName!,
              content: (selectedFile.fileData as string).split(',')[1],
              contentEncodingType: 'base64',
              contentType: selectedFile.contentType,
            },
            order: 1,
          }
        ],
        documentId: documentId!,
      };
      await uploadDocumentExtraAttachmentMutation({
        variables: uploadVariables,
      });
      onClose();
    }
    else {
      await uploadDocument({ documentTitle, selectedFile, isRestrictedEditingEnabled: isRestrictedEditingEnabled });
    }
  };

  const uploadDocument = useCallback(
    async ({ documentTitle, selectedFile, isTrackChanges, isRestrictedEditingEnabled }: UploadFileData) => {
      try {
        if (!selectedFile || !selectedFile.fileData) return;

        const currentUploadType = templateUpload
          ? isDocxUploadModal
            ? convertDocxToDocumentTemplateMutation
            : createDocumentTemplateMutation
          : isDocxUploadModal
          ? createUploadedDocxDocument
          : createUploadedDocument;
        const variables = {
          fileData: {
            content: (selectedFile.fileData as string).split(',')[1],
            contentEncodingType: 'base64',
            contentType: selectedFile.contentType,
          },
          dealId: dealId!,
          teamId: teamId!,
        };

        const result = await currentUploadType({
          // @ts-ignore
          variables: templateUpload
            ? {
                ...variables,
                name: documentTitle!,
                templateType: TemplateType.DOCUMENT,
                restrictedEditing: isRestrictedEditingEnabled,
              }
            : isDocxUploadModal
            ? {
                ...variables,
                title: documentTitle!,
                configuration: { trackChanges: isTrackChanges, restrictedEditing: false },
              }
            : { ...variables, title: documentTitle! },
        });



        onClose();

        if (dealId) {
          const navigateUrl = fillPlaceholders(paths.documentPath, {
            dealId,
            teamId,
            documentId: isDocxUploadModal
              ? (result!.data! as ConvertDocxToDocumentMutation)!.convertDocxToDocument!.id
              : (result!.data! as CreateImportedDocumentMutation)!.createImportedDocument!.id,
          });

          if (!isDocxUploadModal && !templateUpload) {
            sendTotango(TOTANGO_EVENT_TYPES.documentPDFUploaded);
          }
          if (isDocxUploadModal && !templateUpload) {
            sendTotango(TOTANGO_EVENT_TYPES.documentImportedFromWord);
          }

          dispatchLeaseDocumentResponseToStore(
            dispatch,
            // @ts-ignore
            isDocxUploadModal ? result.data.convertDocxToDocument : result.data.createImportedDocument
          );

          navigate(navigateUrl);
        }

        if (templateUpload) {
          const templateUrl = fillPlaceholders(paths.templates.document, {
            templateId: isDocxUploadModal
              ? (result!.data! as ConvertDocxToDocumentTemplateMutation)!.convertDocxToDocumentTemplate!.id
              : (result!.data! as CreateDocumentTemplateMutation)!.createDocumentTemplate!.id,
            teamId,
          });
          if (!isDocxUploadModal) {
            sendTotango(TOTANGO_EVENT_TYPES.templatePDFCreated);
          }
          navigate(templateUrl);
        }
      } catch (e) {
        console.log(e);

        notifyError({
          id: 'upload-file-error',
          message: {
            id: 'document-upload-error',
            defaultMessage: `File upload error: ${e}`,
          },
        });
      }
    },
    [
      createUploadedDocument,
      createDocumentTemplateMutation,
      templateUpload,
      dealId,
      notifyError,
      onClose,
      teamId,
      dispatch,
      createUploadedDocxDocument,
      navigate,
      sendTotango,
    ]
  );

  const handleCloseTrackChanges = () => {
    setIsTrackChangesModal(false);
  };

  const handleConfirmTrackChanges = (isTrack: boolean) => {
    setIsTrackChangesModal(false);
    if (uploadFileData) {
      uploadDocument({
        ...uploadFileData,
        isTrackChanges: isTrack,
      });
    }
  };

  return (
    <FileUploadModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onUploadFileData}
      isLoading={
        createDocumentTemplateLoading ||
        createUploadedDocumentLoading ||
        createUploadedDocxDocumentLoading ||
        convertDocxToDocumentTemplateLoading ||
        uploadDocumentExtraAttachmentsLoading
      }
      templateUpload={templateUpload}
      allowMultiple={allowMultiple}
    />
  );
};

export default FileUploadModalContainer;
