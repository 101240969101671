import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DocumentActivityIcon } from '../../../shared/icons/DocumentActivity.svg';
import { ReactComponent as ClauseTemplateIcon } from '../../../shared/icons/icon-clauses.svg';
import { ReactComponent as DocumentTeamIcon } from '../../../shared/icons/users-group-people.svg';
import { ReactComponent as CloseIcon } from '../../../shared/icons/close-icon.svg';
import { ReactComponent as PlaceholdersIcon } from '../../../shared/icons/placeholders-icon.svg';
import { ReactComponent as AttachmentIcon } from '../../../shared/icons/attachment-icon.svg';
import { DocumentPermissions } from '../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { ClauseTemplatesSidebar } from '../lease-editor/components/clauseTemplatesSidebar';
import { DocumentActivitySidebar } from '../lease-editor/components/documentActivitySidebar';
import styles from './styles.module.scss';
import { DocumentTeamSidebar } from '../../CKEditor/document-team-sidebar';
import Tooltip from '../../tooltip';
import PendingReviewWarningModal from '../lease-document-wizard-header/modals/PendingReviewWarningModal';
import SendModal from '../lease-document-wizard-header/components/headerActions/send/sendModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentDeal } from '../../../store/deals/deals.selectors';
import { DocumentSourceEnum, ShareWithUnresolvedSuggestionsWarning } from '../lease-editor/constants/EditorConstats';
import {
  selectDocumentSource,
  selectIsLeaseEditorLoaded,
} from '../../../store/lease-document/selectors/lease-document.selectors';
import { selectActiveSidenavPlaceholder, selectSidenavPlaceholdersCollapsed } from '../../../store/navigation/navigation.selectors';
import { useDocumentRole } from '../../../shared/hooks/useDocumentRole/useDocumentRole';
import { setClosePendingWarningReviewModal, setShowSendDocumentModal } from '../../../store/modals/modals.actions';
import { useMutation, useQuery } from '@apollo/client';
import { queriesV2 } from '../../../api';
import PlaceholderSidebarContainer from '../lease-editor/components/placeholderSidebar/placeholderSidebarContainer';
import { setActiveSidenavPlaceholder, toggleSidenavPlaceholdersCollapsed } from '../../../store/navigation/navigation.actions';
import { selectIsPendingReviewWarningModalClosed } from '../../../store/modals/modals.selectors';
import DocumentAttachmentsSidebarContainer from '../lease-editor/components/documentAttachmentsSidebar/documentAttachmentsSidebarContainer';

type DocumentActivitiesAndTemplatesNavigationProps = {
  isDocumentCreator: boolean;
  isDocumentRestricted: boolean;
  isCollapsible: boolean;
  permissions: DocumentPermissions;
  onClose: () => void;
  handleSendDocumentMutation: () => Promise<void>;
  canEdit: boolean;
  isReviewing: boolean;
  hasInviteSendingPermission: boolean;
  dealId: string;
  teamId: string;
  documentId: string;
  optionalMessageInputRef: RefObject<HTMLTextAreaElement>;
  onSave: (() => Promise<void>) | undefined;
  canShare: boolean;
  isShared: boolean | undefined;
  isDraft: boolean;
  isUploaded: boolean;
};

enum ActiveSidenavPanel {
  CLAUSES = 'clauses',
  ACTIVITY = 'activity',
  TEAM = 'team',
  PLACEHOLDERS = 'placeholders',
  ATTACHMENTS = 'attachments',
}

const DocumentActivitiesAndTemplatesSidenav = ({
  permissions,
  isDocumentCreator,
  isDocumentRestricted,
  isCollapsible,
  onClose,
  handleSendDocumentMutation,
  canEdit,
  isReviewing,
  hasInviteSendingPermission,
  dealId,
  teamId,
  documentId,
  optionalMessageInputRef,
  onSave,
  canShare,
  isDraft,
  isUploaded,
  isShared,
}: DocumentActivitiesAndTemplatesNavigationProps): JSX.Element => {
  const dispatch = useDispatch();
  const [activeSidenavPanel, setActiveSidenavPanel] = useState<ActiveSidenavPanel | null>(null);
  const [shouldShowToggleButton, setShouldShowToggleButton] = useState(false);

  const isPendingReviewWarningModalClosedExternally = useSelector(selectIsPendingReviewWarningModalClosed);

  const [showWarningReviewModal, setShowWarningReviewModal] = useState(isPendingReviewWarningModalClosedExternally);

  const [showSendToCounterpartyModal, setShowSendToCounterpartyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hasCurrentTeamPossession } = useDocumentRole(documentId);
  const shouldTemplatesOptionBeRendered = isDocumentCreator && !isDocumentRestricted;
  const subNavRef = useRef(null);
  const currentDeal = useSelector(selectCurrentDeal);
  const isEditorLoadedState = useSelector(selectIsLeaseEditorLoaded);
  const documentSource = useSelector(selectDocumentSource);
  const { userRole } = useDocumentRole(documentId);
  const isDealShared = !!currentDeal?.receiverEmail;
  const hasEditPermission = !!permissions?.hasEditPermission;
  const isEditorLoaded = documentSource === DocumentSourceEnum.APP ? isEditorLoadedState : true;

  const { loading: dealTeamsQueryLoading, data: dealTeamsData } = useQuery(queriesV2.getDealTeamsByTeamId, {
    variables: {
      dealId,
    },
  });

  const { loading: documentExtraAttachmentsLoading, data: documentExtraAttachmentsData } = useQuery(queriesV2.getDocumentExtraAttachments, {
    variables: { documentId: String(documentId)! },
    skip: !documentId,
    fetchPolicy: 'network-only',
  });

  const setActiveSidenav = (activePanel: ActiveSidenavPanel | null) => {
    const newActivePanel = activePanel === activeSidenavPanel ? null : activePanel;
    
    setActiveSidenavPanel(newActivePanel);
  };

  const toggleCollapse = () => {
    dispatch(toggleSidenavPlaceholdersCollapsed(true));
    setActiveSidenavPanel(null);
  };

  const handleSendModalHide = () => {
    setShowSendToCounterpartyModal(false);
    dispatch(setShowSendDocumentModal(false));
  };

  useEffect(() => {
    if (isPendingReviewWarningModalClosedExternally === true && showWarningReviewModal) {
      setShowWarningReviewModal(false);
    }
  }, [isPendingReviewWarningModalClosedExternally]);

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');
      if (documentPendingActionsPlugin?.hasAny) {
        event.preventDefault();
        if (isReviewing && hasInviteSendingPermission && !isDocumentCreator) {
          window.history.pushState(null, document.title, window.location.href);
          setShowWarningReviewModal(true);
        }
      }
    };
    const handleBeforePopstate = (event: Event) => {
      const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');
      if (documentPendingActionsPlugin?.hasAny) {
        window.history.pushState(null, document.title, window.location.href);
        if (isReviewing && hasInviteSendingPermission && !isDocumentCreator) {
          setShowWarningReviewModal(true);
        }
      } else {
        window.removeEventListener('popstate', handleBeforePopstate);
        window.history.back();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBeforePopstate);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleClose = () => {
    if (isReviewing && hasInviteSendingPermission && !isDocumentCreator) {
      setShowWarningReviewModal(true);
    } else {
      onClose && onClose();
    }
  };

  const sendDocumentForReview = useCallback(async () => {
    setIsLoading(true);

    if (documentSource === DocumentSourceEnum.IMPORTED) {
      return handleSendDocumentMutation();
    }

    const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

    if (!documentPendingActionsPlugin?.hasAny) {
      return handleSendDocumentMutation();
    }

    documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
      if (!documentPendingActionsPlugin.hasAny) {
        event.off();
        event.stop();
        return handleSendDocumentMutation();
      }
    });
  }, [documentSource, handleSendDocumentMutation]);

  const pendingSamePartySuggestions = () => {
    if (window.editor?.state === 'destroyed') return;

    const suggestions =
      window.editor &&
      window.editor.plugins
        .get('TrackChanges')
        .getSuggestions()
        .filter((item: any) => item.isInContent);

    const counterpartySuggestions = suggestions?.filter((suggestion: any) => {
      return suggestion.attributes.authorRole && suggestion.attributes.authorRole !== userRole;
    });

    return counterpartySuggestions?.length;
  };

  const getPendingSuggestionsAlert = () => {
    const suggestionsNumber = pendingSamePartySuggestions();

    if (!suggestionsNumber) return null;
    return {
      text: ShareWithUnresolvedSuggestionsWarning.text.replace('{num}', `${suggestionsNumber}`),
      title: ShareWithUnresolvedSuggestionsWarning.title,
    };
  };

  const shouldShowInternalSendForSigning =
    isDocumentCreator &&
    hasEditPermission &&
    (isDraft || isReviewing || isUploaded || isShared) &&
    hasCurrentTeamPossession &&
    isEditorLoaded;
  const shouldShowDocumentActionsForReceiver = !isDocumentCreator && hasCurrentTeamPossession && isReviewing;

  const shouldShowSendForSigning = isEditorLoaded && shouldShowDocumentActionsForReceiver && canEdit;

  const isSidenavPlaceholdersCollapsed = useSelector(selectSidenavPlaceholdersCollapsed);

  const [collapsePlaceholderSidenav, setCollapsePlaceholderSidenav] = useState(isSidenavPlaceholdersCollapsed);

  const activeSidenavPlaceholder = useSelector(selectActiveSidenavPlaceholder);

  const handlePlaceholderSidenavClick = () => {
    setActiveSidenav(ActiveSidenavPanel.PLACEHOLDERS);

    dispatch(toggleSidenavPlaceholdersCollapsed(collapsePlaceholderSidenav));
  }

  useEffect(() => {
    if(isSidenavPlaceholdersCollapsed) {
      setActiveSidenavPanel(null);
    } else {
      setActiveSidenavPanel(ActiveSidenavPanel.PLACEHOLDERS);
    }
  }, [isSidenavPlaceholdersCollapsed]);

  return (
    <div className={classNames(styles.subNavMenuWrapper)} ref={subNavRef}>
      <PendingReviewWarningModal
        showModal={showWarningReviewModal}
        hideModal={setShowWarningReviewModal}
        onCloseDocument={onClose}
        canEdit={canEdit}
        documentId={documentId}
        teamId={teamId}
        dealId={dealId}
        dealTeamsData={dealTeamsData}
        onSave={onSave}
        canShare={canShare}
        shouldShowInternalSendForSigning={shouldShowInternalSendForSigning}
        shouldShowSendForSigning={shouldShowSendForSigning}
      />
      <SendModal
        show={showSendToCounterpartyModal}
        onHide={handleSendModalHide}
        isDealShared={isDealShared}
        handler={sendDocumentForReview}
        isLoading={isLoading}
        suggestionsAlert={getPendingSuggestionsAlert?.()}
        optionalMessageInputRef={optionalMessageInputRef}
        teamId={teamId}
      />
      <div className={styles.documentNavigationBar}>
        <button
          type="button"
          onClick={handleClose}
          className={styles.closeButton}
          data-testid="document-header__close-cta"
        >
          <Tooltip
            testId="sidebar-button-activity"
            placement="bottom"
            icon={<CloseIcon className={styles.closeIcon} />}
          >
            Exit
          </Tooltip>
        </button>
        <div
          className={classNames(styles.documentNavigationBar__icon, {
            [styles.active]: isCollapsible && activeSidenavPanel === ActiveSidenavPanel.ACTIVITY,
            [styles.enabled]: isCollapsible,
          })}
          onClick={() => setActiveSidenav(ActiveSidenavPanel.ACTIVITY)}
        >
          <Tooltip
            testId="sidebar-button-activity"
            placement="bottom"
            icon={<DocumentActivityIcon height="22" width="20" />}
          >
            <FormattedMessage id="nav.activity" defaultMessage="Activity" />
          </Tooltip>
        </div>
        {shouldTemplatesOptionBeRendered && (
          <div
            className={classNames(styles.documentNavigationBar__icon, {
              [styles.active]: isCollapsible && activeSidenavPanel === ActiveSidenavPanel.CLAUSES,
              [styles.enabled]: isCollapsible,
            })}
            onClick={() => setActiveSidenav(ActiveSidenavPanel.CLAUSES)}
          >
            <Tooltip
              testId="sidebar-button-clause"
              placement="bottom"
              icon={<ClauseTemplateIcon height="22" width="20" />}
            >
              <FormattedMessage id="nav.clauses" defaultMessage="Clauses" />
            </Tooltip>
          </div>
        )}
        <div
          className={classNames(styles.documentNavigationBar__icon, {
            [styles.active]: isCollapsible && activeSidenavPanel === ActiveSidenavPanel.TEAM,
            [styles.enabled]: isCollapsible,
          })}
          onClick={() => setActiveSidenav(ActiveSidenavPanel.TEAM)}
        >
          <Tooltip testId="sidebar-button-team" placement="bottom" icon={<DocumentTeamIcon height="22" width="20" />}>
            <FormattedMessage id="nav.team" defaultMessage="Participants" />
          </Tooltip>
        </div>
        {!isDocumentRestricted && (
          <div
            className={classNames(styles.documentNavigationBar__icon, {
              [styles.active]: isCollapsible && activeSidenavPanel === ActiveSidenavPanel.PLACEHOLDERS,
              [styles.enabled]: isCollapsible,
            })}
            onClick={handlePlaceholderSidenavClick}
          >
            <Tooltip testId="sidebar-button-team" placement="bottom" icon={<PlaceholdersIcon height="22" width="20" />}>
              <FormattedMessage id="nav.placeholders" defaultMessage="Placeholders" />
            </Tooltip>
          </div>
        )}
        {(
          <div
            className={classNames(styles.documentNavigationBar__icon, {
              [styles.active]: isCollapsible && activeSidenavPanel === ActiveSidenavPanel.ATTACHMENTS,
              [styles.enabled]: isCollapsible,
            })}
            onClick={() => setActiveSidenav(ActiveSidenavPanel.ATTACHMENTS)}
          >
            <Tooltip testId="sidebar-button-team" placement="bottom" icon={<AttachmentIcon height="22" width="20" />}>
              <FormattedMessage id="nav.attachments" defaultMessage="Attachments" />
            </Tooltip>
          </div>
        )}
      </div>
      {isCollapsible && (
        <div
          id="lease-wizard-side-nav"
          className={classNames(styles.subNav, { [styles.collapsed]: !activeSidenavPanel })}
          aria-expanded={!activeSidenavPanel}
          onMouseEnter={() => setShouldShowToggleButton(true)}
          onMouseLeave={() => setShouldShowToggleButton(false)}
        >
          <button
            className={classNames(styles.subNavToggle, { [styles.subNavToggle__hidden]: !shouldShowToggleButton })}
            type="button"
            aria-expanded={!activeSidenavPanel}
            aria-controls="main-sub-nav"
            onClick={toggleCollapse}
          />
          <div className={styles.subNavMask}>
            <div className={styles.subNavContent}>
              {shouldTemplatesOptionBeRendered && (
                <div style={{ display: `${activeSidenavPanel === ActiveSidenavPanel.CLAUSES ? 'block' : 'none'}` }}>
                  <ClauseTemplatesSidebar permissions={permissions} />
                </div>
              )}
              <div style={{ display: `${activeSidenavPanel === ActiveSidenavPanel.ACTIVITY ? 'block' : 'none'}` }}>
                <DocumentActivitySidebar />
              </div>
              <div style={{ display: `${activeSidenavPanel === ActiveSidenavPanel.TEAM ? 'block' : 'none'}` }}>
                <DocumentTeamSidebar />
              </div>
              {!isDocumentRestricted && (
                <div style={{ display: `${activeSidenavPanel === ActiveSidenavPanel.PLACEHOLDERS ? 'block' : 'none'}` }}>
                  <PlaceholderSidebarContainer activeUuid={activeSidenavPlaceholder} />
                </div>
              )}
              <div style={{ display: `${activeSidenavPanel === ActiveSidenavPanel.ATTACHMENTS ? 'block' : 'none'}` }}>
                <DocumentAttachmentsSidebarContainer activeUuid={null} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentActivitiesAndTemplatesSidenav;
